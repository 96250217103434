import cx from 'classnames';
import { Table } from 'reactstrap';
import SortButton from 'common/SortButton/SortButton';
import ColoredCircle from '../../ColoredCircle/ColoredCircle';
import EmployeeTitle from 'common/EmployeeTitle/EmployeeTitle';

import { useSort } from 'hooks/sortHook';
import { COLORS } from 'config/theme';

import sortHeaderDto from 'models/dto/sort.dto';
import EmployeeHRModel from 'models/employeeHR.model';

import styles from '../../tableStyles.module.scss';
import gradeStyle from '../../ProfileDashboard/ProfileHeader/profileHeader.module.scss';

interface PropsT {
  data: EmployeeHRModel[];
  headers: sortHeaderDto[];
}

const EmployeesTableForHR = ({ data, headers }: PropsT) => {
  const { sortedData, sortKey, sortOrder, onSortingChange } = useSort({ data });

  return sortedData?.length ? (
    <div className={styles.tableWrapper}>
      <Table
        responsive
        hover
        className={cx(styles.tableContainer, 'align-middle mb-0 custom-table text-truncate text-left')}
      >
        <thead>
          <tr>
            <th>#</th>
            {headers?.map(item => (
              <th key={item.value}>
                <SortButton
                  onClick={() => onSortingChange(item.value, item.sortType)}
                  sortKey={sortKey}
                  sortOrder={sortOrder}
                  sortField={item.value}
                  sortTitle={item.title}
                />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((employee: EmployeeHRModel, index: number) => (
            <tr
              style={
                employee.dismissalDate
                  ? { color: 'var(--danger)' }
                  : employee.hasStartedThisMonth
                  ? { color: 'var(--success)' }
                  : {}
              }
              key={employee.email}
            >
              <td>{index + 1}</td>
              <td>
                {employee.dismissalDate ? <span>--</span> : ''}
                {employee.hasStartedThisMonth ? <span>++</span> : ''}
                <a
                  style={
                    employee.dismissalDate
                      ? { color: 'var(--danger)' }
                      : employee.hasStartedThisMonth
                      ? { color: 'var(--success)' }
                      : {}
                  }
                  href={`#/dashboard/profile/${employee.id}`}
                >
                  {employee.email}
                </a>
                <EmployeeTitle employee={employee} />
              </td>
              <td>
                <div className={gradeStyle.gradeWrap}>
                  <div className={employee.grade && gradeStyle[employee.grade.toLowerCase()]}>{employee.grade}</div>
                </div>
              </td>
              <td>{employee.team}</td>
              <td>{employee.location}</td>
              <td>{employee.city}</td>
              <td>{employee.birthday}</td>
              <td>{employee.englishLevel}</td>
              <td>{employee.startDate}</td>
              <td>{employee.trialEndDate}</td>
              <td>{employee.dismissalDate}</td>
              <td>
                {employee.salaryPrediction}
                {employee.hasUnapprovedObligations && (
                  <span className="ml-2">
                    <ColoredCircle color={COLORS.red} />
                  </span>
                )}
              </td>
              <td>
                {employee.confluencePrivatePage && (
                  <a href={`${employee.confluencePrivatePage}`} target="_blank">
                    {employee.fullName}
                  </a>
                )}
              </td>
              <td>{employee.nextPerformanceReviewDate}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  ) : null;
};

export default EmployeesTableForHR;
