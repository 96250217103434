import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { ThunkAction } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';

import { RootState } from 'App';
import { enqueueSnackbar } from './notifications';
import { NotificationType } from 'services/notificationService';

import { generateSortingUrl } from '../utils/urls';
import { RequestParams } from 'utils/mapParams';

type ActionTypeT = {
  request: string;
  success: string;
  failure: string;
};

interface TeamInfoT {
  closeDate: string | null;
  confluenceTeamleadsGroup: string;
  departmentId: number;
  email: string;
  id: number;
  isAvailableForPlanning: boolean;
  openDate: string | null;
  teamleadId: number | null;
  title: string;
}

export interface EmployeeInfoT {
  position?: number;
  atlassianId: string;
  availability: number;
  birthday?: string;
  city?: string;
  departament: { title: string; id: number };
  dismissalDate?: string;
  email: string;
  firstName: string;
  grade?: string;
  englishLevel?: string;
  hasStartedThisMonth: boolean;
  hasUnapprovedObligations: boolean;
  id: number;
  isDirectCost: boolean;
  isEngineeringlead: boolean;
  isIntern: boolean;
  isTeamlead: boolean;
  isTechlead: boolean;
  lastName: string;
  location?: string;
  privateInfo?: {
    address?: string;
    confluencePrivatePage?: string;
    nextPerformanceReviewDate?: string;
    wearSize?: string;
  };
  salaryPrediction: number;
  salesGrade?: {
    id: number;
    title: string;
  };
  startDate: string;
  team: TeamInfoT;
  timeByMonth: number;
  title: string;
  trialEndDate: string;
  vsdStats?: { available?: number };
}

interface ActionT {
  type: string;
  loading: boolean;
  date: Date | null;
  data: EmployeeInfoT | null;
  payload: PayloadAction;
  error: null | Error;
}

const EVENTS_EMPLOYEES = 'EMPLOYEES/EVENTS_EMPLOYEES_';

export const EVENT_EMPLOYEES_ACTION_TYPE = {
  WORKFORCE_COSTS_REQUEST: `${EVENTS_EMPLOYEES}WORKFORCE_COSTS_REQUEST`,
  WORKFORCE_COSTS_SUCCESS: `${EVENTS_EMPLOYEES}WORKFORCE_COSTS_SUCCESS`,
  WORKFORCE_COSTS_FAILURE: `${EVENTS_EMPLOYEES}WORKFORCE_COSTS_FAILURE`,

  TEAM_LEAD_REQUEST: `${EVENTS_EMPLOYEES}TEAM_LEAD_REQUEST`,
  TEAM_LEAD_SUCCESS: `${EVENTS_EMPLOYEES}TEAM_LEAD_SUCCESS`,
  TEAM_LEAD_FAILURE: `${EVENTS_EMPLOYEES}TEAM_LEAD_FAILURE`,

  DELIVERY_TEAM_EMPLOYEES_REQUEST: `${EVENTS_EMPLOYEES}DELIVERY_TEAM_EMPLOYEES_REQUEST`,
  DELIVERY_TEAM_EMPLOYEES_SUCCESS: `${EVENTS_EMPLOYEES}DELIVERY_TEAM_EMPLOYEES_SUCCESS`,
  DELIVERY_TEAM_EMPLOYEES_FAILURE: `${EVENTS_EMPLOYEES}DELIVERY_TEAM_EMPLOYEES_FAILURE`,
};

const initialState = {
  loading: false,
  allWorkforceEmployees: null,
  temLeadEmployees: null,
  deliveryTeamEmployees: null,
  error: null,
};

export default function eventReducer(state = initialState, action: ActionT) {
  switch (action.type) {
    case EVENT_EMPLOYEES_ACTION_TYPE.WORKFORCE_COSTS_REQUEST: {
      return {
        ...state,
        loading: true,
        allWorkforceEmployees: null,
      };
    }
    case EVENT_EMPLOYEES_ACTION_TYPE.WORKFORCE_COSTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        allWorkforceEmployees: action.data,
      };
    }
    case EVENT_EMPLOYEES_ACTION_TYPE.WORKFORCE_COSTS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case EVENT_EMPLOYEES_ACTION_TYPE.TEAM_LEAD_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case EVENT_EMPLOYEES_ACTION_TYPE.TEAM_LEAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        temLeadEmployees: action.data,
      };
    }
    case EVENT_EMPLOYEES_ACTION_TYPE.TEAM_LEAD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case EVENT_EMPLOYEES_ACTION_TYPE.DELIVERY_TEAM_EMPLOYEES_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case EVENT_EMPLOYEES_ACTION_TYPE.DELIVERY_TEAM_EMPLOYEES_SUCCESS: {
      return {
        ...state,
        loading: false,
        deliveryTeamEmployees: action.data,
      };
    }
    case EVENT_EMPLOYEES_ACTION_TYPE.DELIVERY_TEAM_EMPLOYEES_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
}

export const getEmployees =
  (params: Partial<RequestParams>, actionType: ActionTypeT): ThunkAction<void, RootState, unknown, AnyAction> =>
  async dispatch => {
    dispatch({ type: actionType.request });
    try {
      const urlParams = generateSortingUrl(params);
      const data = await API.get('helloAPI', `/employees?${urlParams}`, {});
      const employees = data.employees.map((el: EmployeeInfoT, index: number) => ({
        ...el,
        position: index + 1,
        department: el.team?.title,
      }));
      dispatch({ type: actionType.success, data: { ...data, employees } });
    } catch (error: any) {
      dispatch({ type: actionType.failure, error });
      dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.error,
            body: error?.message ? error.message : 'Error',
            title: 'Page data',
          },
        })
      );
      return Promise.reject(error);
    }
  };
