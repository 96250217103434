import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, startOfMonth } from 'date-fns';

import { Card, CardBody } from 'reactstrap';
import EmployeesTableForHR from './EmployeesTableForHR';
import Loader from 'common/Loader';
import Search from 'common/Search/Search';
import TeamFilter from 'common/TeamFilter/TeamFilter';
import Datepicker from 'components/Datepicker/Datepicker';

import { getEmployees } from 'store/allEmployees/allEmployees.thunk';
import { AppState } from 'store/configureStore';
import { EmployeeInfoT } from 'store/eventsEmployees';
import { RequestParams } from 'utils/mapParams';
import EmployeeHRModel from 'models/employeeHR.model';

import { useQuery } from 'hooks/queryHook';
import { DATE_PICKER } from 'constants/common';
import { EMPLOYEES_PAGE_TABLE_HEADERS } from 'constants/peopleTableHeaders';

const HrEmployees = () => {
  const dispatch = useDispatch();

  const { employees, loading } = useSelector((state: AppState) => state.allEmployeesReducer);

  const { date, team, search, setDate, setTeam, setSearchData } = useQuery();

  const [searchValue, setSearchValue] = useState(search ? search : '');

  const getEmployeesData = useCallback(() => {
    if (!date) {
      setDate(format(startOfMonth(new Date()), DATE_PICKER.dateFormatToPayload));
    } else {
      const params: Partial<RequestParams> = { date: date, expand: 'finance,profile,private' };

      if (team) {
        params.team = +team;
      }
      if (search) {
        params.search = search;
      }

      dispatch(getEmployees({ params }));
    }
  }, [dispatch, date, team, search]);

  useEffect(() => {
    getEmployeesData();
  }, [dispatch, getEmployeesData]);

  const onDateChange = (date: Date) => {
    setDate(format(startOfMonth(date), DATE_PICKER.dateFormatToPayload), searchValue);
  };

  const updateSearchValue = (event: React.FormEvent) => {
    setSearchData(searchValue.trim());
    event.preventDefault();
  };

  const employeesTableData: EmployeeHRModel[] = employees?.map((item: EmployeeInfoT) => {
    return new EmployeeHRModel({
      city: item?.city,
      birthday: item.birthday,
      confluencePrivatePage: item.privateInfo?.confluencePrivatePage,
      dismissalDate: item.dismissalDate,
      email: item.email,
      englishLevel: item.englishLevel,
      fullName: `${item.firstName} ${item.lastName}`,
      grade: item.grade,
      hasStartedThisMonth: item.hasStartedThisMonth,
      hasUnapprovedObligations: item.hasUnapprovedObligations,
      id: item.id,
      isEngineeringlead: item.isEngineeringlead,
      isTeamlead: item.isTeamlead,
      isTechlead: item.isTechlead,
      location: item.location,
      nextPerformanceReviewDate: item.privateInfo?.nextPerformanceReviewDate,
      position: item.position,
      salaryPrediction: item.salaryPrediction,
      startDate: item.startDate,
      team: item.team?.title,
      title: item.title,
      trialEndDate: item.trialEndDate,
    });
  });

  return (
    <Card className="main-card mb-3">
      <CardBody>
        <div className="bp-header">
          <div className="filters-block">
            <Search searchValue={searchValue} setSearchValue={setSearchValue} updateSearchValue={updateSearchValue} />

            <TeamFilter team={team} setTeam={setTeam} searchData={searchValue} />

            <div className="dropdown-filter">
              <p className="label-wrapper">Date</p>
              <Datepicker
                selected={date ? new Date(date) : startOfMonth(new Date())}
                dateFormat={DATE_PICKER.dateFormatMonth}
                showMonthYearPicker
                onChange={onDateChange}
              />
            </div>
          </div>
        </div>

        {employeesTableData?.length ? (
          <EmployeesTableForHR data={employeesTableData || []} headers={EMPLOYEES_PAGE_TABLE_HEADERS} />
        ) : loading ? (
          <Loader />
        ) : (
          'Employees are empty'
        )}
      </CardBody>
    </Card>
  );
};

export default HrEmployees;
